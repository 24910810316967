import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Gtag } from 'angular-gtag';
import { filter, map, mergeMap } from 'rxjs/operators';

import { CommonService } from './services/common.service';
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Buildfix';
  sub: any;

  constructor(public router: Router, public gtag : Gtag, private _seoService : CommonService,private activatedRoute : ActivatedRoute) {
    // this.router.events .pipe(
    //   filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
    //    console.log('-------->>>>>>>>>>>>>',event)
       
    //  }); 

    //  this._seoService.updateTitle(event['title']);
    //    this._seoService.updateOgUrl(event['ogUrl']);
    //    //Updating Description tag dynamically with title
    //    this._seoService.updateDescription(event['description'])
    //    this._seoService.updateRobots(event['robot'])


  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
     )
     .subscribe((event) => {
       console.log(event);
            this._seoService.updateTitle(event['title']);
       this._seoService.updateOgUrl(event['ogUrl']);
       //Updating Description tag dynamically with title
       this._seoService.updateDescription(event['description'])
       this._seoService.updateRobots(event['robot'])
     })
  }
   
  }