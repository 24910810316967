import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { InspectionComponent } from "./modules/inspection/inspection/inspection.component";

const routes: Routes = [
  // { path: 'Enquiry',  loadChildren: () => import("../app/modules/inspection/inspection.module").then(m => m.InspectionModule) },
  {
    path: "",
    redirectTo: "Enquiry/Request-a-free-inspection",
    pathMatch: "full",
  },
  {
    path: "",
    loadChildren:
    "../app/modules/webpage/webpage.module#WebpageModule",
  },
  {
    path: "Enquiry",
    loadChildren:
      "../app/modules/inspection/inspection.module#InspectionModule",
  },
  {
    path: "quote-details",
    loadChildren:
      "../app/modules/bookinglink/bookinglink.module#BookinglinkModule",
  },
  {
    path: "quality",
    loadChildren:
      "../app/modules/qualityassurance/qualityassurance.module#QualityassuranceModule",
  },
  {
    path: "forms",
    loadChildren: "../app/modules/forms/inventory.module#InventoryModule",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // enableTracing: true,
      useHash:false
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
