import { Injectable } from "@angular/core";
import { RestRequest } from "./rest-request";
import { ToastrManager } from "ng6-toastr-notifications";
import { Meta, Title } from "@angular/platform-browser";

@Injectable()
export class CommonService {
  constructor(
    private restRequest: RestRequest,
    public toastr: ToastrManager,
    private title: Title,
    private meta: Meta
  ) {}

  getCountries() {
    return this.restRequest.get("countries");
  }

  toast(msg: String, type: String) {
    if (type === "Success") {
      this.toastr.successToastr(msg.toString(), "Success!", {
        showCloseButton: true,
        toastTimeout: 6000,
      });
    } else if (type === "Error") {
      this.toastr.errorToastr(msg.toString(), "Error!", {
        showCloseButton: true,
        toastTimeout: 6000,
      });
    } else {
      this.toastr.infoToastr(msg.toString(), "Info!", {
        showCloseButton: true,
        toastTimeout: 6000,
      });
    }
  }

  getCurrencies(country: String) {
    return this.restRequest.get("currencies?country=" + country);
  }

  getStates(country_id: String) {
    return this.restRequest.get("states?country_id=" + country_id);
  }

  getLanguages() {
    return this.restRequest.get("languages");
  }

  createAttribute(payload) {
    return this.restRequest.post("attribute", payload);
  }

  attributeList(page, limit) {
    return this.restRequest.get("attributes?page=" + page + "&limit=" + limit);
  }

  sendFeedbackData(data) {
    return this.restRequest.post("users/feedback?quoteStatus=declined", data);
  }

  getAttribute(categoryId) {
    return this.restRequest.get("attribute/" + categoryId);
  }

  getTerms() {
    return this.restRequest.newGet("users/get_terms");
  }

  updateAttribute(categoryId, payload) {
    return this.restRequest.put("attribute/" + categoryId, payload);
  }

  createJobs(payload) {
    return this.restRequest.post("job", payload);
  }

  submitComment(payload) {
    return this.restRequest.put("users/updateComment", payload);
  }

  fileUpload(file) {
    const formData: FormData = new FormData();
    formData.append("file", file);
    return this.restRequest.post("users/uploadImage", formData);
  }
  /* pdfupload(pdf){
        const formData: FormData = new FormData();
        formData.append('file', pdf);
        return this.restRequest.post('users/uploadpdf', formData);
    }*/
  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ name: "og:url", content: url });
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: "description", content: desc });
  }

  updateRobots(robot: string) {
    this.meta.updateTag({ name: "robots", content: robot });
  }
}
