// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // APIEndpoint: "http://localhost:3000/api/v1",
  // DOMAIN_URL: "http://localhost:4200/",
  // APIEndpoint: "http://18.224.7.123:3000/api/v1", // QA new
  // DOMAIN_URL: "http://3.134.244.56:5000/",
  APIEndpoint: "https://api.buildfix.com.au/api/v1", // Production Server

  // APIEndpoint:
  //   "https://784a-2409-4041-ce91-4af4-ad00-7604-af2e-c1c0.ngrok-free.app/api/v1",

  // APIEndpoint: 'http://3.105.189.94:3000/api/v1', // Production Server
  //DOMAIN_URL: 'http://3.105.189.94:5000/',
  // DOMAIN_URL: 'https://live.buildfix.com.au/',
  // APIEndpoint: "http://apibuildfix.abcxyz.in/api/v1", ///
  // APIEndpoint: 'http://3.24.187.165:3000/api/v1', // QA
  //DOMAIN_URL: 'http://3.24.187.165:5000/',
  // DOMAIN_URL: 'http://3.24.187.165:4200/',
  // DOMAIN_URL: 'http://52.63.38.11:4200/', // Production
  // APIEndpoint: 'http://52.63.38.11:3000/api/v1', // Production Server Updated
  defaultPageSize: 10,
  googleAnalytics: {
    domain: "auto",
    trackingId: "UA-XXXXXXXX-X", // replace with your Tracking Id
  },
  recaptcha: {
    siteKey: "6Lf8cskhAAAAAKGTIXJzQNqN-lPjJZRDYjJfyamD",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
